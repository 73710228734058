<!--  -->
<template>
	<div class="app-container">
		<div class="search_box">
			<el-form :inline="true" :model="searchForm" class="demo-form-inline">
				<el-form-item class="formLabel" label="部门">
					<!--<treeselect
            :props="depProps"
            :options="deps"
            :value="searchForm.department"
            :clearable="true"
            :accordion="true"
            :level="searchForm.department"
            @getValue="_getValue($event)" />!-->
					<el-input v-model="searchForm.departmentName" size="small" placeholder="请输入部门名称"></el-input>
				</el-form-item>
				<el-form-item class="formLabel" label="岗位">
					<el-input v-model="searchForm.jobName" size="small" placeholder="请输入岗位名称"></el-input>
					<!--<el-select
            placeholder="请选择岗位"
            style="width:100%;"
            filterable
            clearable
            v-model="searchForm.jobId"
          >
            <el-option
              v-for="item in jobList"
              :key="item.id"
              :label="item.jobOrOfficeName"
              :value="item.id"
            ></el-option>
          </el-select>!-->
				</el-form-item>
				<el-form-item class="formLabel" label="姓名">
					<el-input v-model="searchForm.staffName" size="small" placeholder="请输入姓名"></el-input>
				</el-form-item>
				<el-form-item class="formLabel" label="手机号">
					<el-input v-model="searchForm.phone" size="small" placeholder="请输入手机号"></el-input>
				</el-form-item>
				<el-form-item class="formLabel" label="创建时间">
					<el-date-picker
						type="date"
						size="small"
						:clearable="false"
						value-format="yyyy-MM-dd"
						:picker-options="pickerOptionsStart2"
						placeholder="选择开始时间"
						v-model="searchForm.createStartTime"
						style="width: 150px"
					></el-date-picker>

					<span style="padding: 0 3px">-</span>
					<el-date-picker
						type="date"
						:picker-options="pickerOptionsStart2"
						size="small"
						:clearable="false"
						value-format="yyyy-MM-dd"
						placeholder="选择结束时间"
						v-model="searchForm.createEndTime"
						style="width: 150px"
					></el-date-picker>
				</el-form-item>
				<el-form-item class="fr" style="margin-right: 0">
					<el-button type="primary" @click="query" size="small" style="margin-left: 15px" icon="el-icon-search">查询</el-button>
					<el-button
						type="primary"
						@click="exportOut"
						size="small"
						style="margin-left: 15px"
						icon="el-icon-download"
						v-if="permissionControlBtns(pageName, 'Export')"
						>导出</el-button
					>
					<el-button
						type="primary"
						@click="toDingDing"
						size="small"
						icon="el-icon-office-building"
						style="margin-left: 15px"
						v-if="permissionControlBtns(pageName, 'ToDing')"
						>进入钉钉平台</el-button
					>
					<el-button
						type="primary"
						@click="toSetting"
						size="small"
						style="margin-left: 15px"
						icon="el-icon-setting"
						v-if="permissionControlBtns(pageName, 'Set')"
						>设置</el-button
					>
				</el-form-item>
			</el-form>
			<section v-loading="loadding">
				<el-table :data="tableList" v-if="!loadding" style="width: 100%" header-row-class-name="tableClass">
					<!--<el-table-column type="index" label="序号" :width="80" align="center" :index="indexMethod"></el-table-column>!-->
					<el-table-column label="姓名" align="center" prop="staffName" fixed></el-table-column>
					<el-table-column label="工号" align="center" prop="workNumber"></el-table-column>
					<el-table-column label="部门" align="center" prop="departmentName"></el-table-column>
					<el-table-column label="岗位" align="center" prop="jobName"></el-table-column>
					<template v-for="(col, i) in cols">
						<el-table-column
							:key="i"
							v-if="copycheckedCities.includes(col.label)"
							align="center"
							:prop="col.id"
							:width="col.width"
							:label="col.label"
						>
							<template slot-scope="{ row }">
								<el-link type="primary" :underline="false" v-if="col.onclick" @click="cellClick('0', row, col)">{{
									row[col.id]
								}}</el-link>
								<div v-else>{{ row[col.id] }}</div>
							</template>
						</el-table-column>
					</template>
					<el-table-column v-for="(col, i1) in weeks" :key="col.id" align="center" :width="128">
						<template slot="header">
							<p :style="col.label.style">
								{{ col.label.week }}<span class="tacday">{{ col.label.day }}</span>
							</p>
						</template>
						<template slot-scope="{ row }">
							<p v-show="row.totalTimeResponseList[i1].attendanceAm">
								<el-link
									type="primary"
									:style="returncolor(row.totalTimeResponseList[i1].attendanceAm)"
									@click="cellClick('1', row.totalTimeResponseList[i1], col, row)"
									:underline="false"
									>{{ row.totalTimeResponseList[i1] && row.totalTimeResponseList[i1].attendanceAm }}</el-link
								>
							</p>
							<p v-show="row.totalTimeResponseList[i1].attendancePm">
								<el-link
									type="primary"
									:style="returncolor(row.totalTimeResponseList[i1].attendancePm)"
									@click="cellClick('1', row.totalTimeResponseList[i1], col, row)"
									:underline="false"
									>{{ row.totalTimeResponseList[i1] && row.totalTimeResponseList[i1].attendancePm }}</el-link
								>
							</p>
						</template>
					</el-table-column>
				</el-table>
			</section>
			<el-pagination
				class="fr"
				style="margin: 20px 0"
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="table.pageNo"
				:page-sizes="[20, 50, 100, 200]"
				:page-size="table.pageSize"
				layout="total, sizes, prev, pager, next, jumper"
				:total="table.total"
			></el-pagination>
		</div>
		<!-- 设置弹框 -->
		<el-dialog title="展示列" :visible.sync="settingDialog" center width="40%">
			<div style="padding-left: 7%; margin-bottom: 10px">
				<el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
				<div style="margin: 15px 0"></div>
				<el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
					<el-checkbox v-for="city in cities" :label="city" style="margin: 10px" :key="city">{{ city }}</el-checkbox>
				</el-checkbox-group>
			</div>
			<span slot="footer">
				<el-button
					type="primary"
					@click="
						copycheckedCities = checkedCities;
						settingDialog = false;
					"
					>确 定</el-button
				>
			</span>
		</el-dialog>
		<!-- 弹框 -->
		<el-dialog :visible.sync="dialogTableVisible" custom-class="attendance_dialog">
			<template slot="title">
				<div class="attendance_info" v-show="type == '0'">
					<span style="margin-right: 5px">{{ userInfo.name }}</span>
					<span>{{ userInfo.dates }}</span>
					<span>{{ userInfo.abnormaltype }}汇总</span>
					<span style="color: #777b7f">(次数：{{ userInfo.total }}次)</span>
				</div>
				<div class="attendance_info" style="text-align: center" v-show="type == '1'">
					<span style="margin-right: 5px">{{ userInfo.name }}</span>
					<span style="margin-right: 5px">{{ userInfo.dates }}</span>
					<span>周{{ userInfo.weeks }}</span>
				</div>
			</template>
			<div class="staff_info" v-show="type == '1'">
				<div>
					<el-avatar shape="circle" :size="80" fit="fill" :src="userInfo.avatarUrl"></el-avatar>
				</div>
				<div class="attendance_detail">
					<div class="namebox">{{ userInfo.name }}</div>
					<div class="classbox">
						<span style="margin-right: 20px">班次：{{ userInfo.workType }}</span>
						<span>考勤组：{{ userInfo.attendanceGroup }}</span>
					</div>
					<div class="resultbox">工时共计{{ userInfo.workHours }}</div>
				</div>
			</div>
			<el-table :data="gridData" border style="margin-top: 10px">
				<el-table-column property="date" label="考勤时间" align="center"></el-table-column>
				<el-table-column label="打卡时间" align="center" v-if="type == '0'">
					<template slot-scope="{ row }">
						{{ row.clockTimeAm ? row.clockTimeAm : row.clockTimePm }}
					</template>
				</el-table-column>
				<el-table-column label="打卡时间" align="center" prop="clockTime" v-else></el-table-column>
				<el-table-column property label="打卡结果" align="center">
					<template slot-scope="{ row }">
						<span style="color: #f79a23">{{ row.attendance }}</span>
					</template>
				</el-table-column>
				<el-table-column property="workType" label="考勤班次" align="center" v-if="type == '0'"></el-table-column>
				<el-table-column property="address" label="打卡地点" align="center" v-if="type == '1'"></el-table-column>
			</el-table>

			<!-- 修改打卡结果弹框 -->
			<!--<el-dialog width="30%" :visible.sync="innerDialogUpdate" append-to-body>
        <template slot="title">
          <span>修改打卡结果为</span>
        </template>
        <div>
        <el-radio-group v-model="changeForm.attendance">
          <el-radio label="2">正常</el-radio>
          <el-radio label="1">迟到</el-radio>
        </el-radio-group>
          <el-input  v-model="changeForm.date" style="width:80px;margin-left:10px"></el-input>
          <span>分钟</span>
        </div>

        <div slot="footer" center class="dialog-footer">
          <el-button type="primary" @click="updateResult">确 定</el-button>
        </div>
      </el-dialog>!-->
		</el-dialog>
	</div>
</template>

<script>
import {
	attendanceUpdate,
	getAttendanceList,
	normalAttendance,
	leavedaysAttendance,
	latedaysAttendance,
	lackofCardAmAttendance,
	lackofCardPmAttendance,
	absenteeismAttendance
} from '../../api/time';
import { timestampToTime } from '../../utils/util';
// import treeselect from "@/components/treeSelect/treeSelect";
const cityOptions = [
	'出勤天数',
	'休息天数',
	'工作时长',
	'迟到次数',
	// "严重迟到次数",
	'旷工天数',
	'早退次数',
	'上班缺卡次数',
	'下班缺卡次数'
];

export default {
	props: { pageName: {} },
	// components: {treeselect},
	data() {
		return {
			loadding: false,
			checkAll: false,
			checkedCities: ['出勤天数', '休息天数', '工作时长'],
			copycheckedCities: [],
			weeks: [],
			cities: cityOptions,
			isIndeterminate: true,
			searchForm: {
				staffName: '',
				departmentName: '',
				phone: '',
				jobName: '',
				createStartTime: new Date(new Date().setMonth(new Date().getMonth() - 1)),
				createEndTime: new Date()
			},
			pickerOptionsStart2: {
				disabledDate(time) {
					return time.getTime() > new Date(new Date().toLocaleDateString()).getTime();
				}
			},
			changeForm: {
				attendance: '',
				date: ''
			},
			userInfo: {
				staffId: '',
				name: '',
				dates: '',
				abnormaltype: '',
				total: '',
				workType: '',
				attendanceGroup: '',
				workHours: '',
				weeks: '',
				avatarUrl: 'https://ehr-prod.oss-cn-shenzhen.aliyuncs.com/template/878b8e2202ca46bdbc479c7ebe1cd460.png'
			},
			deps: this.$store.state.setData.depsList,
			depProps: {
				value: 'id',
				label: 'orgName',
				children: 'children'
			},
			// {label: "严重迟到次数",id: "seriousMiners",width:"120",onclick:true}
			cols: [
				{ label: '出勤天数', id: 'attendanceDays' },
				{ label: '休息天数', id: 'restDays' },
				{ label: '工作时长', id: 'totalWorkHours' },
				{ label: '迟到次数', id: 'lateNumber', onclick: true },
				{ label: '旷工天数', id: 'miners', onclick: true },
				{ label: '早退次数', id: 'leaveEarly', onclick: true },
				{ label: '上班缺卡次数', id: 'lackAmCard', width: '120', onclick: true },
				{ label: '下班缺卡次数', id: 'lackPmCard', width: '120', onclick: true }
			],
			tableList: [],
			table: {
				total: 0,
				pageNo: 1,
				pageSize: 20
			},
			jobList: [],
			departmentList: [], // 部门
			type: '0',
			// 弹框数据
			gridData: [],
			settingDialog: false,
			dialogTableVisible: false,
			innerDialogUpdate: false,
			radio: '0'
		};
	},
	computed: {
		dict() {
			return this.$store.state.app.dict;
		},
		dictData() {
			const Arr = {
				sexList: [],
				sorceList: [],
				experiencelist: [],
				educationList: []
			};

			Array.isArray(this.dict) &&
				this.dict.forEach((item) => {
					if (item.groupId === 'sex') {
						Arr.sexList.push({ value: item.dictId, label: item.dictName });
					}
				});
			return Arr;
		}
	},
	mounted() {
		// this.getJobList();
		// this.getDepartmentList();
		this.copycheckedCities = this.checkedCities;
		this.getList();
	},
	methods: {
		// 排序
		indexMethod(index) {
			return index + (this.table.pageNo - 1) * this.table.pageSize + 1;
		},
		_getValue(eve) {
			this.searchForm.department = eve.id;
		},
		query() {
			this.pageNo = 1;
			this.getList();
		},
		getList() {
			this.loadding = true;
			getAttendanceList({
				...this.searchForm,
				createStartTime:
					typeof this.searchForm.createStartTime === 'string'
						? this.searchForm.createStartTime
						: timestampToTime(this.searchForm.createStartTime.getTime(), true),
				createEndTime:
					typeof this.searchForm.createEndTime === 'string'
						? this.searchForm.createEndTime
						: timestampToTime(this.searchForm.createEndTime.getTime(), true),
				pageNo: this.table.pageNo,
				pageSize: this.table.pageSize
			}).then((res) => {
				if (res.list) {
					this.weeks = [];
					if (res.list.length) {
						res.list[0].totalTimeResponseList.forEach((it) => {
							switch (new Date(it.date).getDay()) {
								case 0: {
									this.weeks.push({ label: { week: '日', style: 'color:#FF604D', day: new Date(it.date).getDate() }, id: it.date });
									break;
								}
								case 1: {
									this.weeks.push({ label: { week: '一', day: new Date(it.date).getDate() }, id: it.date });
									break;
								}
								case 2: {
									this.weeks.push({ label: { week: '二', day: new Date(it.date).getDate() }, id: it.date });
									break;
								}
								case 3: {
									this.weeks.push({ label: { week: '三', day: new Date(it.date).getDate() }, id: it.date });
									break;
								}
								case 4: {
									this.weeks.push({ label: { week: '四', day: new Date(it.date).getDate() }, id: it.date });
									break;
								}
								case 5: {
									this.weeks.push({ label: { week: '五', day: new Date(it.date).getDate() }, id: it.date });
									break;
								}
								case 6: {
									this.weeks.push({ label: { week: '六', style: 'color:#FF604D', day: new Date(it.date).getDate() }, id: it.date });
									break;
								}
							}
						});
					}
					this.tableList = res.list;
					this.table.total = res.totalNum;
				}
				this.loadding = false;
			});
		},
		returncolor(word) {
			if (word.includes('正常')) {
				return 'color:#00C648';
			}
			if (word.includes('迟到')) {
				return 'color:#FF7800';
			}
			if (word.includes('休息')) {
				return 'color:#9547FF';
			}
			if (word.includes('早退')) {
				return 'color:#FF7800';
			}
			if (word.includes('缺卡')) {
				return 'color:#FF0000';
			}
			if (word.includes('正常')) {
				return 'color:#00C648';
			}
			if (word.includes('旷工')) {
				return 'color:#FF0000';
			}
			if (word.includes('补签')) {
				return 'color:#FFBC13';
			}
			if (word.includes('请假')) {
				return 'color:#409EFF';
			}
		},
		// 导出
		exportOut() {
			const params = {
				...this.searchForm,
				pageNo: this.table.pageNo,
				pageSize: this.table.pageSize
			};

			const url = `${document.location.origin}/v1/attendance/total/export?params=${encodeURIComponent(JSON.stringify(params))}`;

			window.open(url);
		},
		// 进入钉钉平台
		toDingDing() {
			window.open('https://attend.dingtalk.com/portal/index.html');
		},
		// 设置
		toSetting() {
			this.settingDialog = true;
		},
		cellClick(type, row, col, bigrow) {
			this.type = type;
			if (type == 0) {
				this.userInfo.name = row.staffName;
				this.userInfo.abnormaltype = col.label;
				this.userInfo.dates = `${
					typeof this.searchForm.createStartTime === 'string'
						? this.searchForm.createStartTime
						: timestampToTime(this.searchForm.createStartTime.getTime(), true)
				}~${
					typeof this.searchForm.createEndTime === 'string'
						? this.searchForm.createEndTime
						: timestampToTime(this.searchForm.createEndTime.getTime(), true)
				}`;
				const params = {
					staffId: row.staffId,
					createStartTime:
						typeof this.searchForm.createStartTime === 'string'
							? this.searchForm.createStartTime
							: timestampToTime(this.searchForm.createStartTime.getTime(), true),
					createEndTime:
						typeof this.searchForm.createEndTime === 'string'
							? this.searchForm.createEndTime
							: timestampToTime(this.searchForm.createEndTime.getTime(), true)
				};

				switch (col.id) {
					case 'lateNumber': {
						params.attendanceAmStatus = '1';
						latedaysAttendance(params).then((res) => {
							if (res._responseStatusCode == 0) {
								delete res._responseStatusCode;
								if (res) {
									this.userInfo.total = res.length;
									res.map((it) => {
										it.date = timestampToTime(it.date);
									});
									this.gridData = res;
								}
							}
						});
						break;
					}
					case 'leaveEarly': {
						params.attendanceAmStatus = '8';
						leavedaysAttendance(params).then((res) => {
							if (res._responseStatusCode == 0) {
								delete res._responseStatusCode;
								if (res) {
									this.userInfo.total = res.length;
									res.map((it) => {
										it.date = timestampToTime(it.date);
									});
									this.gridData = res;
								}
							}
						});
						break;
					}
					case 'miners': {
						params.attendanceAmStatus = '6';
						absenteeismAttendance(params).then((res) => {
							if (res._responseStatusCode == 0) {
								delete res._responseStatusCode;
								if (res) {
									this.userInfo.total = res.length;
									res.map((it) => {
										it.date = timestampToTime(it.date);
									});
									this.gridData = res;
								}
							}
						});
						break;
					}
					case 'lackAmCard': {
						lackofCardAmAttendance(params).then((res) => {
							if (res._responseStatusCode == 0) {
								delete res._responseStatusCode;
								if (res) {
									this.userInfo.total = res.length;
									res.map((it) => {
										it.date = timestampToTime(it.date);
									});
									this.gridData = res;
								}
							}
						});
						break;
					}
					case 'lackPmCard': {
						lackofCardPmAttendance(params).then((res) => {
							if (res._responseStatusCode == 0) {
								delete res._responseStatusCode;
								if (res) {
									this.userInfo.total = res.length;
									res.map((it) => {
										it.date = timestampToTime(it.date);
									});
									this.gridData = res;
								}
							}
						});
						break;
					}
					// case "seriousMiners":{
					//   params.attendanceAmStatus="7";
					//   latedaysAttendance(params).then(res=>{
					//     if(res._responseStatusCode==0){
					//       delete res._responseStatusCode;
					//       if(res){
					//         this.userInfo.total=res.length;
					//         res.map(it=>{
					//           it.date=timestampToTime(it.date);
					//         })
					//         this.gridData=res;
					//       }
					//     }
					//   })
					//   break;
					// }
				}
			} else {
				this.userInfo.name = bigrow.staffName;
				this.userInfo.dates = row.attendanceDate;
				this.userInfo.weeks = col.label.week;
				this.userInfo.staffId = row.staffId;
				const params = {
					staffId: row.staffId,
					date: row.attendanceDate
				};

				normalAttendance(params).then((res) => {
					this.userInfo.workType = res.workType;
					if (res.avatarUrl) {
						this.userInfo.avatarUrl = res.avatarUrl;
					}
					this.userInfo.attendanceGroup = res.attendanceGroup;
					this.userInfo.workHours = res.workHours;
					this.gridData = [
						{ date: res.workAmTime, clockTime: res.clockAmTime, attendance: res.attendanceAm, address: res.clockAmPlace },
						{ date: res.workPmTime, clockTime: res.clockPmTime, attendance: res.attendancePm, address: res.clockPmPlace }
					];
				});
			}
			this.dialogTableVisible = true;
		},
		// 修改打卡结果
		updateResult() {
			const params = {
				staffId: this.userInfo.staffId,
				staffName: this.userInfo.name,
				attendance: this.changeForm.attendance,
				date: this.changeForm.date
			};

			attendanceUpdate(params).then((res) => {
				if (res._responseStatusCode == 0) {
					this.$message.success('打卡结果修改成功');
					this.innerDialogUpdate = false;

					normalAttendance({ staffId: this.userInfo.staffId, date: this.userInfo.dates }).then((res) => {
						this.userInfo.workType = res.workType;
						this.userInfo.avatarUrl = res.avatarUrl;
						this.userInfo.attendanceGroup = res.attendanceGroup;
						this.userInfo.workHours = res.workHours;
						this.gridData = [
							{ date: res.workAmTime, clockTime: res.clockAmTime, attendance: res.attendanceAm, address: res.clockAmPlace },
							{ date: res.workPmTime, clockTime: res.clockPmTime, attendance: res.attendancePm, address: res.clockPmPlace }
						];
					});
				}
			});
		},

		getJobList() {
			this.$axios
				.post('/v1/org/queryOfficeJob/list', {
					request: {
						type: '2', // 1：职位 2：岗位
						name: '',
						pageNo: 1,
						pageSize: 20000
					},
					sign: ''
				})
				.then((res) => {
					this.jobList = res.list;
				});
		},
		// 获取部门
		getDepartmentList() {
			this.$axios
				.post('/v1/org/queryStaffJob/list', {
					request: {},
					sign: ''
				})
				.then((res) => {
					this.departmentList = res;
				});
		},
		handleSizeChange(val) {
			this.table.pageSize = val;
			this.getList();
		},

		handleCurrentChange(val) {
			this.table.pageNo = val;
			this.getList();
		},
		handleCheckAllChange(val) {
			this.checkedCities = val ? cityOptions : [];
			this.isIndeterminate = false;
		},
		handleCheckedCitiesChange(value) {
			const checkedCount = value.length;

			this.checkAll = checkedCount === this.cities.length;
			this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
		}
	}
};
</script>
<style lang="scss" scoped>
.container {
	padding: 20px;
}
.tacday {
	vertical-align: -webkit-baseline-middle;
	margin-left: 5px;
}
.search_box {
	margin: 10px 0;
}
.attendance_dialog {
	.staff_info {
		padding: 20px 0 20px 22px;
		display: flex;
		.namebox {
			transform: translateY(10px);
			font-size: 16px;
		}
		.classbox {
			transform: translateY(10px);
			color: #777e8c;
		}
		.resultbox {
			transform: translateY(-4px);
			color: #777e8c;
			font-size: 14px;
		}
	}
	.attendance_detail {
		margin-left: 10px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
}
</style>
<style lang="scss">
.formLabel {
	.el-form-item__label {
		font-weight: normal;
	}
}
.attendance_dialog {
	.el-dialog__header {
		padding: 20px;
		border-bottom: 1px solid #ebebeb;
	}
	.el-dialog__body {
		padding-top: 0;
	}
}
</style>
