<template>
	<div class="app-container">
		<el-tabs v-model="activeName" @tab-click="handleClick">
			<el-tab-pane label="原始记录" name="1" v-if="permissionControl('OriginalRecord')">
				<oldRecord ref="attendanceList1" :index="2" :activeTab="activeName" v-if="activeName === '1'" pageName="OriginalRecord" />
			</el-tab-pane>
			<el-tab-pane label="打卡时间" name="2" v-if="permissionControl('ClockTime')">
				<clockTime ref="attendanceList2" :activeTab="activeName" v-if="activeName === '2'" pageName="ClockTime" />
			</el-tab-pane>
			<el-tab-pane label="考勤汇总" name="3" v-if="permissionControl('AttendanceSummary')">
				<attendanceAll ref="attendanceList3" :activeTab="activeName" v-if="activeName === '3'" pageName="AttendanceSummary" />
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
import oldRecord from '@/views/attendanceManage/oldRecord';
import clockTime from '@/views/attendanceManage/clockTime';
import attendanceAll from '@/views/attendanceManage/attendanceAll';
// import orgConfig from '../orgConfig.vue';
export default {
	name: 'attendanceList',
	components: { oldRecord, clockTime, attendanceAll },

	data() {
		return { activeName: '' };
	},

	mounted() {
		const data = JSON.parse(sessionStorage.getItem('attendanceListData'));
		const { activeName, listQuery } = data || {};

		this.activeName = activeName || '1';
		if (listQuery) {
			this.listQuery = listQuery;
		}
		// 设置tab默认
		this.setActiveTab(this.$route.name, [
			{ name: '1', key: 'OriginalRecord' },
			{ name: '2', key: 'ClockTime' },
			{ name: '3', key: 'AttendanceSummary' }
		]);
	},
	methods: {
		handleClick() {
			sessionStorage.setItem(
				'attendanceListData',
				JSON.stringify({
					activeName: this.activeName
				})
			);
		}
	}
};
</script>

<style lang="scss" scoped></style>
