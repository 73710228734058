<!--  -->
<template>
	<div class="app-container">
		<div class="search_box">
			<el-form :inline="true" :model="searchForm" class="demo-form-inline">
				<el-form-item class="formLabel" label="部门">
					<!--<treeselect
            :props="depProps"
            :options="deps"
            :value="searchForm.department"
            :clearable="true"
            :accordion="true"
            :level="searchForm.department"
            @getValue="_getValue($event)" />!-->
					<el-input v-model="searchForm.departmentName" size="small" placeholder="请输入部门名称"></el-input>
				</el-form-item>
				<el-form-item class="formLabel" label="岗位">
					<el-input v-model="searchForm.jobName" size="small" placeholder="请输入岗位名称"></el-input>
					<!--<el-select
            placeholder="请选择岗位"
            style="width:100%;"
            filterable
            size="small"
            clearable
            v-model="searchForm.jobId"
          >
            <el-option
              v-for="item in jobList"
              :key="item.id"
              :label="item.jobOrOfficeName"
              :value="item.id"
            ></el-option>
          </el-select>!-->
				</el-form-item>
				<el-form-item class="formLabel" label="姓名">
					<el-input v-model="searchForm.staffName" size="small" placeholder="请输入姓名"></el-input>
				</el-form-item>
				<el-form-item class="formLabel" label="手机号">
					<el-input v-model="searchForm.phone" size="small" placeholder="请输入手机号"></el-input>
				</el-form-item>
				<el-form-item class="formLabel" label="考勤时间">
					<el-date-picker
						type="date"
						size="small"
						:picker-options="pickerOptionsStart2"
						value-format="yyyy-MM-dd"
						placeholder="选择开始时间"
						:clearable="false"
						v-model="searchForm.createStartTime"
						style="width: 150px"
					></el-date-picker>

					<span style="padding: 0 3px">-</span>
					<el-date-picker
						type="date"
						value-format="yyyy-MM-dd"
						:picker-options="pickerOptionsStart2"
						size="small"
						:clearable="false"
						placeholder="选择结束时间"
						v-model="searchForm.createEndTime"
						style="width: 150px"
					></el-date-picker>
				</el-form-item>
				<el-form-item class="fr" style="margin-right: 0">
					<el-button type="primary" @click="query" size="small" style="margin-left: 10px" icon="el-icon-search">查询</el-button>
					<el-button
						type="primary"
						@click="exportOut"
						size="small"
						style="margin-left: 10px"
						icon="el-icon-download"
						v-if="permissionControlBtns(pageName, 'Export')"
						>导出</el-button
					>
					<el-button
						type="primary"
						@click="toDingDing"
						icon="el-icon-office-building"
						size="small"
						style="margin-left: 20px"
						v-if="permissionControlBtns(pageName, 'ToDing')"
						>进入钉钉平台</el-button
					>
				</el-form-item>
			</el-form>
			<section v-loading="loadding">
				<el-table :data="tableList" style="width: 100%" header-row-class-name="tableClass" v-if="!loadding">
					<!--<el-table-column type="index" label="序号" :width="80" align="center" :index="indexMethod"></el-table-column>!-->
					<el-table-column
						v-for="(col, i) in cols"
						:key="cols.id + String(i)"
						:label="col.label"
						:fixed="col.id == 'staffName' || col.id == 'workNumber'"
						align="center"
						:prop="col.id"
					>
					</el-table-column>
					<el-table-column v-for="(week, i1) in weeks" :key="i1" align="center" :width="100">
						<template slot="header">
							<p :style="week.label.style">
								{{ week.label.week }}<span class="tacday">{{ week.label.day }}</span>
							</p>
						</template>
						<template slot-scope="{ row }">
							<p style="height: 23px">{{ row.timeResponseList[i1] && row.timeResponseList[i1].clockAmTime }}</p>
							<p style="height: 23px">{{ row.timeResponseList[i1] && row.timeResponseList[i1].clockPmTime }}</p>
						</template>
					</el-table-column>
				</el-table>
				<el-pagination
					class="fr"
					style="margin: 20px 0"
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="table.pageNo"
					:page-sizes="[20, 50, 100, 200]"
					:page-size="table.pageSize"
					layout="total, sizes, prev, pager, next, jumper"
					:total="table.total"
				></el-pagination>
			</section>
		</div>
	</div>
</template>

<script>
import { getClocktimeList } from '../../api/time';
import { timestampToTime } from '../../utils/util';
// import treeselect from "@/components/treeSelect/treeSelect";
export default {
	props: { pageName: {} },
	// components: {treeselect},
	data() {
		return {
			loadding: false,
			searchForm: {
				staffName: '',
				departmentName: '',
				phone: '',
				jobName: '',
				createStartTime: new Date(new Date().setMonth(new Date().getMonth() - 1)),
				createEndTime: new Date()
			},
			pickerOptionsStart2: {
				disabledDate(time) {
					return time.getTime() > new Date(new Date().toLocaleDateString()).getTime();
				}
			},
			deps: this.$store.state.setData.depsList,
			depProps: {
				value: 'id',
				label: 'orgName',
				children: 'children'
			},
			table: {
				total: 0,
				pageNo: 1,
				pageSize: 20
			},
			jobList: [],
			weeks: [],
			cols: [
				{ label: '姓名', id: 'staffName' },
				{ label: '工号', id: 'workNumber' },
				{ label: '部门', id: 'department' },
				{ label: '岗位', id: 'jobName' }
			],
			tableList: [],
			departmentList: [] // 部门
		};
	},
	computed: {
		dict() {
			return this.$store.state.app.dict;
		},
		dictData() {
			const Arr = {
				sexList: [],
				sorceList: [],
				experiencelist: [],
				educationList: []
			};

			Array.isArray(this.dict) &&
				this.dict.forEach((item) => {
					if (item.groupId === 'sex') {
						Arr.sexList.push({ value: item.dictId, label: item.dictName });
					}
				});
			return Arr;
		}
	},
	mounted() {
		this.getJobList();
		this.getDepartmentList();
		this.getClockData();
	},
	methods: {
		// 排序
		indexMethod(index) {
			return index + (this.table.pageNo - 1) * this.table.pageSize + 1;
		},
		getValue(eve) {
			this.searchForm.department = eve.id;
		},
		getClockData() {
			this.loadding = true;
			const params = {
				...this.searchForm,
				createStartTime:
					typeof this.searchForm.createStartTime === 'string'
						? this.searchForm.createStartTime
						: timestampToTime(this.searchForm.createStartTime.getTime(), true),
				createEndTime:
					typeof this.searchForm.createEndTime === 'string'
						? this.searchForm.createEndTime
						: timestampToTime(this.searchForm.createEndTime.getTime(), true),
				pageNo: this.table.pageNo,
				pageSize: this.table.pageSize
			};

			getClocktimeList(params).then((res) => {
				if (res.list) {
					this.weeks = [];
					res.list[0] &&
						res.list[0].timeResponseList.forEach((it) => {
							switch (new Date(it.date).getDay()) {
								case 0: {
									this.weeks.push({ label: { week: '日', style: 'color:#FF604D', day: new Date(it.date).getDate() } });
									break;
								}
								case 1: {
									this.weeks.push({ label: { week: '一', day: new Date(it.date).getDate() } });
									break;
								}
								case 2: {
									this.weeks.push({ label: { week: '二', day: new Date(it.date).getDate() } });
									break;
								}
								case 3: {
									this.weeks.push({ label: { week: '三', day: new Date(it.date).getDate() } });
									break;
								}
								case 4: {
									this.weeks.push({ label: { week: '四', day: new Date(it.date).getDate() } });
									break;
								}
								case 5: {
									this.weeks.push({ label: { week: '五', day: new Date(it.date).getDate() } });
									break;
								}
								case 6: {
									this.weeks.push({ label: { week: '六', style: 'color:#FF604D', day: new Date(it.date).getDate() } });
									break;
								}
							}
						});
					this.tableList = res.list;
					this.loadding = false;
					this.table.total = res.totalNum;
				}
			});
		},
		query() {
			this.pageNo = 1;
			this.getClockData();
		},
		// 导出
		exportOut() {
			const params = {
				...this.searchForm
			};

			const url = `${document.location.origin}/v1/attendance/clocktime/export?params=${encodeURIComponent(JSON.stringify(params))}`;

			window.open(url);
		},
		// 进入钉钉平台
		toDingDing() {
			window.open('https://attend.dingtalk.com/portal/index.html');
		},
		getJobList() {
			this.$axios
				.post('/v1/org/queryOfficeJob/list', {
					request: {
						type: '2', // 1：职位 2：岗位
						name: '',
						pageNo: 1,
						pageSize: 20000
					},
					sign: ''
				})
				.then((res) => {
					this.jobList = res.list;
				});
		},
		// 获取部门
		getDepartmentList() {
			this.$axios
				.post('/v1/org/queryStaffJob/list', {
					request: {},
					sign: ''
				})
				.then((res) => {
					this.departmentList = res;
				});
		},
		handleSizeChange(val) {
			this.table.pageSize = val;
			this.getClockData();
		},

		handleCurrentChange(val) {
			this.table.pageNo = val;
			this.getClockData();
		}
	}
};
</script>
<style lang="scss" scoped>
.container {
	padding: 20px;
}
.tacday {
	vertical-align: -webkit-baseline-middle;
	margin-left: 5px;
}
.search_box {
	margin: 10px 0;
}
</style>
<style lang="scss">
.formLabel {
	.el-form-item__label {
		font-weight: normal;
	}
}
</style>
